<template>
    <!-- Download Mobile App -->
    <section class="dowbload-app">
        <div class="container">
            <div class="row py-5 con">
                <div class="col-lg-7 col-xl-8 py-5">
                    <div class="title-block text-center">
                        <h1>Download Mobile App</h1>
                        <!-- <div class="line-block">
                            <span class="bullet"><fa icon="leaf" /></span>
                        </div> -->
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6 px-2 text-center py-3">
                            <h4>For Client</h4>
                            <ul class="list-inline list-unstyled m-0">
                                <li class="list-inline-item my-1 m-0">
                                    <a href="#">
                                        <img src="@/assets/images/img/apple-store.png" class="img-fluid" alt="" />
                                    </a>
                                </li>
                                <li class="list-inline-item my-1 m-0">
                                    <a href="#">
                                        <img src="@/assets/images/img/play-store.png" class="img-fluid" alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 px-2 text-center py-3">
                            <h4>For Business</h4>
                            <ul class="list-inline list-unstyled m-0">
                                <li class="list-inline-item my-1 m-0">
                                    <a href="#">
                                        <img src="@/assets/images/img/apple-store.png" class="img-fluid" alt="" />
                                    </a>
                                </li>
                                <li class="list-inline-item my-1 m-0">
                                    <a href="#">
                                        <img src="@/assets/images/img/play-store.png" class="img-fluid" alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Download Mobile App -->
</template>
<script setup></script>